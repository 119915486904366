<template>
  <div class="initiate-inventory">
    <PageHeader title="发起盘点" />
    <div class="searchView flex a-center m-tb-10 j-end p-lr-20">
      <!--      <div class="flex j-start">-->
      <!--        <div class="flex a-center  m-right-20">-->
      <!--          <div class="f14 p-right-10">盘点仓库</div>-->
      <!--          <el-select v-model="info.StockCode" remote filterable size="mini" style="width:90px" placeholder="请选择">-->
      <!--            <el-option-->
      <!--              v-for="(item,index) in BuyersTerminal"-->
      <!--              :key="index"-->
      <!--              :label="item.label"-->
      <!--              :value="item.value"-->
      <!--            />-->
      <!--          </el-select>-->

      <!--        </div>-->
      <!--        <el-button size="mini" round @click="pdProducts">盘点该仓库下的所有产品</el-button>-->
      <!--      </div>-->
      <div class="flex">
        <div class="ModelBttton-white-30 m-right-10" @click="delProducts">
          <i class="el-icon-upload2 f15 m-right-5" />
          <span>批量删除物料</span>
        </div>
        <div class="ModelBttton-white-30 m-right-10" @click="showAddProduct">
          <i class="el-icon-zoom-in f15 m-right-5" />
          <span>添加产品</span>
        </div>
        <el-button size="mini" round type="primary" @click="showSetNameDialog(1)">保存</el-button>
        <el-button size="mini" round type="primary" @click="showSetNameDialog(2)">发起盘点</el-button>

      </div>
    </div>

    <div class="p-lr-20">
      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        class="multipleTable"
        :data="info.Details"
        tooltip-effect="dark"
        show-header
        height="calc(100vh - 380px)"
        row-key="StockSellID"
        border
        :fit="true"
        @selection-change="checkProducts"
      >
        <!--    @select-all="selectAll"
        @select="select" -->
        <el-table-column type="selection" width="40" />
        <el-table-column label="物料编号" prop="InvCode" show-overflow-tooltip />
        <el-table-column label="物料名称" prop="InvName" show-overflow-tooltip />
        <el-table-column label="品牌" prop="BrandName" show-overflow-tooltip />
        <el-table-column label="物料类型" prop="MaterialTypeName" show-overflow-tooltip />
        <el-table-column label="规格" prop="Specification" show-overflow-tooltip />
        <el-table-column label="单位" prop="StockUnitN" show-overflow-tooltip />
        <el-table-column label="批号/序列号" prop="LotNum" show-overflow-tooltip />
        <el-table-column label="效期" prop="ExpiredDate" show-overflow-tooltip />
        <el-table-column label="库存数量" prop="StockNum" show-overflow-tooltip />
        <el-table-column label="发起人" prop="LunchUserName" show-overflow-tooltip />
        <el-table-column label="UDI码" prop="DINum" show-overflow-tooltip />
        <el-table-column label="盘点仓库" prop="StockName" show-overflow-tooltip />
        <el-table-column label="操作" prop="" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-link type="primary" @click="delProduct(scope.$index,scope.row)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <!-- 备注 -->
      <div class="flex a-center m-tb-20" style="width: 50%">
        <div style="width:100px"> 盘点备注：</div> <el-input v-model="info.Remark" size="mini" />
      </div>
      <div class="pagination-container">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          :current-page.sync="currentPage"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      </div>
    </div>
    <el-dialog class="DepartmentBox" :visible.sync="visible" custom-class="dialog_view" :show-close="false">
      <div class="flex j-around">
        <div class="flex a-center  m-right-20">
          <div class="f14 p-right-10" style="width: 60px">盘点仓库</div>
          <el-select v-model="info.StockCode" clearable remote filterable size="mini" style="width:90px;margin-right: 10px" placeholder="请选择">
            <el-option
              v-for="(item,index) in BuyersTerminal"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-button size="mini" round @click="pdProducts">盘点该仓库下的所有产品</el-button>
        </div>
        <div class="PageHeader1 min-border-bottom flex j-between">
          <span class="Name">添加商品</span>
          <i class="el-dialog__close el-icon el-icon-close Close" @click="() => visible = false" />
        </div>

        <div class="search searchView flex j-around m-tb-20">
          <div class="flex m-right-20" style="flex: 1">
            <el-input
              v-model="produceSearch.KeyWords"
              size="medium"
              placeholder="可通过物料号、物料名称搜索"
              class="rule-input-edit"
            >
              <!-- <template slot="prepend">
              <div class="pointer" @click="() => visibl1e = true">
                <i class="el-icon-s-operation" />
                <span>高级搜索</span>
              </div>
            </template> -->
              <div
                slot="append"
                @click="searchAdADD"
              >
                搜 索
              </div>
            </el-input>
          </div>
          <el-button style="font-size:14px" size="mini" icon="el-icon-folder-add" round @click="addGoodS">批量添加</el-button>
        </div>
      </div>
      <el-table
        ref="tableDataProduct"
        :data="tableDataProduct"
        :header-cell-style="headerCStyle"
        border
        max-height="500px"
        @selection-change="isChangeP"
      >
        <!--   -->
        <el-table-column
          type="selection"
          width="40"
          :selectable="checkboxInit"
          fixed="left"
        />
        <!--   :selectable="checkboxInit" -->
        <el-table-column align="center" property="InvCode" label="物料编码" show-overflow-tooltip />
        <el-table-column align="center" property="InvName" label="物料名称" show-overflow-tooltip />
        <el-table-column align="center" property="BrandName" label="物料品牌" show-overflow-tooltip />
        <el-table-column align="center" property="MaterialTypeName" label="物料类别" show-overflow-tooltip />
        <el-table-column align="center" property="Specification" label="物料规格" show-overflow-tooltip />
        <el-table-column align="center" property="StockUnitN" label="物料单位" show-overflow-tooltip />
        <el-table-column align="center" property="StockNum" label="可用数量" show-overflow-tooltip />
        <el-table-column align="center" property="a" fixed="right" label="操作" width="50">
          <template v-if="!scope.row.disabled" slot-scope="scope">
            <el-link :underline="false" @click="addGoodOne(scope.row)">添加</el-link>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container p-bottom-20">
        <el-pagination
          :ref="paginationName1"
          :total="tableTotalSize1"
          :pager-count="pagerCount1"
          :page-size="tablePageSize1"
          :background="tableBackground1"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange1($event)"
        />
      </div>
    </el-dialog>
    <!-- 修改备注 start -->
    <el-dialog :visible.sync="showSetName" custom-class="dialog_view1" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <p class="p-left-20 weight">输入盘点名称</p>
        <p class="p-right-10 weight f16 cursor" style="color:#000" @click="() => showSetName = false">x</p>
      </div>
      <el-input
        v-model="info.StockCorrectName"
        type="textarea"
        :autosize="{ minRows: 4, maxRows: 8}"
        placeholder="请输入内容"
      />
      <div class="p-top-20 min-border-top flex a-center j-center">
        <el-button size="mini" round @click="() => showSetName = false">取消</el-button>
        <el-button size="mini" type="primary" round @click="SaveStockCorrectInfo">确定</el-button>
      </div>
    </el-dialog>
    <!-- 修改备注 end -->
  </div>
</template>
<script>
import Index from '@/minxin/management/initiateInventory'
export default Index
</script>
<style scoped lang="scss">
@import "@/style/search.scss";
</style>


<template>
  <div class="Adsearch">
    <el-dialog class="DepartmentBox" :close-on-click-modal="false" width="800px!important" custom-class="dialog_view" :visible.sync="show">
      <div class="PageHeader1 min-border-bottom">
        <span class="Name">高级搜索</span>
      </div>
      <el-form ref="form" class="InfoBoxTwo3 m-top-10" :model="form" :inline="true" label-width="100px">
        <el-form-item v-for="(item,index) in adSearchForm" :key="index" :label="item.name" :prop="item.value">
          <el-date-picker
            v-if="item.date"
            v-model="form[item.value]"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="mini"
            :unlink-panels="true"
            align="center"
            style="width:100%!important"
            value-format="yyyy-MM-dd"
          />
          <div v-if="item.check">
            <el-checkbox-group v-model="form[item.value]">
              <el-checkbox v-for="(i,n) in item.checkBox" :key="n" :label="i.name" />
            </el-checkbox-group>
          </div>
          <div v-if="item.radio">
            <el-radio-group v-model="form[item.value]">
              <el-radio v-for="(i,n) in item.checkBox" :key="n" :label="i.name" />
            </el-radio-group>
          </div>
          <el-input v-if="!item.check && !item.date && !item.radio" v-model="form[item.value]" size="mini" autocomplete="off" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="adSearchCancel">取 消</el-button>
        <el-button :loading="$store.state.index.isLodingComponent" type="primary" @click="adSearchSubmit">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'Adsearch',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    adSearchForm: {
      type: Array,
      default: () => []
    },
    form: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    adSearchCancel() {
      this.$emit('cancel', false)
      this.$refs['form'].resetFields()
    },
    adSearchSubmit() {
      this.$emit('searchAd')
      // this.$refs['form'].resetFields()
    }
  }
}
</script>
<style scoped lang='scss'>
.dialog_view{
    position: relative;
}
 .PageHeader{
    width: 100%;
    height: 50px;
    background-color: #fff;
    position: absolute;
    left: 0;
    top:0;
    display: flex;
    color:   #006fda;
    &::after{
        content: '';
        width: 3px;
        height:50%;
        background-color:  #006fda;
        position: absolute;
        left: 0;
        top: 25%;
    }
 }
</style>

<template>
  <div :class="[border ? ' min-border-bottom':'', dilog ? 'PageHeader_log' : 'PageHeader']">
    <div :class="dilog ? 'title theme-font f20 flex  p-lr-20 m-top-10' : 'title flex theme-font f17  p-lr-20'">
      {{ title }}
      <div v-if="icon" class="m-lr-10">
        <el-popover
          placement="right-start"
          title=""
          trigger="hover"
        >
          <span v-html="iconContent" />
          <i slot="reference" class="el-icon-info" />

        </el-popover>
      </div>

    </div>
    <div v-if="dilog" :class="center ? 'slot flex j-left a-left p-right-50':'slot flex j-end a-left p-left-50' ">
      <slot />
    </div>
    <div v-else :class="center ? 'slot flex j-center a-center p-right-10':'slot flex j-end a-center p-right-10' ">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'PageHeader',
  props: {
    title: {
      type: String,
      default: '我是标题'
    },
    center: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: true
    },
    dilog: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    },
    iconContent: {
      type: String,
      default: '我是标题'
    }
  }
}
</script>
<style lang='scss' >
.PageHeader_log{
    width: 100%;
    height: 50px;
    background-color: #fff;
    position: relative;
    left: 0;
    display: flex;
    &::after{
        content: '';
        width: 3px;
        height:50%;
        background-color:  #006fda;
        position: absolute;
        left: 0;
        top: 45%;
    }
    .title{
        width: auto;
        min-width: 200px;
        line-height: 50px;
        margin-top: 10px;
    }
    .slot{
        flex:1;
    }
}
.PageHeader{
    width: 100%;
    height: 50px;
    background-color: #fff;
    position: relative;
    left: 0;
    display: flex;
    &::after{
        content: '';
        width: 3px;
        height:50%;
        background-color:  #006fda;
        position: absolute;
        left: 0;
        top: 25%;
    }
    .title{
        width: auto;
        min-width: 200px;
        line-height: 50px;
    }
    .slot{
        flex:1;
    }
}
</style>

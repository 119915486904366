import PageHeader from '@/components/PageHeader.vue'
import Adsearch from '@/components/Adsearch'
import tableMixin from '@/minxin/table-minxin'
const Index = {
  name: 'initiateInventory',
  components: {
    PageHeader,
    Adsearch
  },
  mixins: [tableMixin],
  data() {
    return {
      formSearch: { KeyWords: '', StockCorrectName: '' },
      info: { Details: [] },
      BuyersTerminal: [],
      paginationName1: 'tablePagination1',
      tableTotalSize1: 0,
      pagerCount1: 5,
      tablePageSize1: 10,
      tableBackground1: true,
      visible: false,
      tableDataProduct: [],
      tempArrP: [],
      showSetName: false,
      type: 0,
      productsArr: [],
      produceSearch: {
        KeyWords: '',
        PageIndex: 1,
        PageSize: 10,
        InvCode: '',
        InvName: '',
        StockCode: ''
      }
    }
  },
  beforeUpdate() {
    this.$nextTick(() => { // 在数据加载完，重新渲染表格
      this.$refs.multipleTable.doLayout()
    })
  },
  mounted() {
    console.log(this.$route.params)
    this.QueryInStockList() // 获取仓库数据
    if (this.$route.params.idx !== '0' && this.$route.params.idx !== 0) {
      this.GetStockCorrectInfoByIDX(this.$route.params.idx) // 如果是未盘点的则请求详情数据
    }
  },
  methods: {
    isChangeP(e) {
      console.log(e)
      this.tempArrP = e
    },
    // 选择要提交的数据
    checkProducts(e) {
      this.productsArr = e
      console.log(e)
    },
    // 处理重复数据
    setDataArr(row) {
      const result = this.info.Details.filter(item => item.StockSellID === row.StockSellID)
      if (result.length > 0) return
      this.info.Details.push(row)
    },
    // 盘点该仓库下的产品
    async pdProducts() {
      if (!this.info.StockCode) return this.$message.warning('请选择仓库')
      const result_ = await this.GetStockSellByStockInv('')
      if (result_.RetCode !== '0') return this.$message.error('错误：' + result_.RetMsg)
      result_.Message.map(item1 => {
        this.setDataArr(item1)
      })
      this.$message.info('系统将自动过滤已添加过的产品')
      this.visible = false
    },
    checkboxInit(row, index) {
      if (row.disabled) {
        return 0// 不可勾选
      } else {
        return 1// 可勾选
      }
    },
    // 新增/保存 盘点
    SaveStockCorrectInfo() {
      const obj = {
        IDX: this.$route.params.idx ? this.$route.params.idx : 0,
        Status: this.type, // 1: 未发起 2：盘点中 3：已完成
        StockCorrectName: this.info.StockCorrectName,
        Remark: this.info.Remark,
        Details: []
      }
      obj.Details = this.info.Details
      console.log(obj)
      this.$api.SaveStockCorrectInfo(obj).then(res => {
        console.log(res)
        this.showSetName = false
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.$router.push({
          name: 'inventoryCorrection',
          params: { isCache: 6 }
        })
        const newArrIndex = this.$store.state.index.cachedViews.findIndex(item1 => item1 === 'initiateInventory')
        this.$store.state.index.cachedViews.splice(newArrIndex, 1)
        this.$store.dispatch('index/setCachedViews', this.$store.state.index.cachedViews)
        return this.$message.success(this.type === 1 ? '保存成功' : '发起成功')
      }).catch(err => {
        this.showSetName = false
        return this.$message.error('错误：' + err)
      })
    },
    // 展示盘点名称弹框
    showSetNameDialog(type) {
      if (this.info.Details.length <= 0) return this.$message.warning('请选择你要盘点的产品')
      this.type = type
      this.showSetName = true
    },
    // 批量删除物料
    delProducts() {
      if (this.productsArr.length <= 0) return this.$message.warning('请选择你要删除的产品')
      const result = this.info.Details.filter(item => !this.productsArr.some(item1 => item1.StockSellID === item.StockSellID))
      this.info.Details = result
    },
    // 添加商品 搜索
    searchAdADD() {
      this.handleCurrentChange1()
    },
    // 获取详情数据
    async GetStockCorrectInfoByIDX(IDX) {
      const result = await this.$api.GetStockCorrectInfoByIDX({ IDX })
      this.info = result.Message
      console.log(result)
    },
    async handleCurrentChange1(page) {
      this.produceSearch.PageIndex = page || 1
      this.produceSearch.PageSize = this.tablePageSize1
      this.produceSearch.StockCode = this.info.StockCode
      const { Message, Total, RetCode } = await this.$api.GetMateialStockListByStockCode(this.produceSearch)
      console.log({ Message, Total, RetCode })
      if (RetCode === '0' && Message.length >= 0) {
        console.log(Message)
        this.tableTotalSize1 = Total
        this.tableDataProduct = Message
        this.tableDataProduct.map(item => {
          this.$set(item, 'disabled', false)
        })
        this.checkProduceIs()
      }
    },
    // 批量添加
    async addGoodS() {
      const result = this.tempArrP.filter(item => item.disabled === false)
      if (result.length <= 0) return this.$message.warning('请选择你要添加的商品')
      const length = result.filter(item => item.StockNum <= 0)
      if (length.length > 0) return this.$message.warning('可用数量不能小于1')
      let IDX_ = ''
      result.map((item, index) => {
        if (index !== result.length - 1) {
          return IDX_ += item.IDX + ','
        }
        return IDX_ += item.IDX
      })
      const result_ = await this.GetStockSellByStockInv(IDX_)
      if (result_.RetCode !== '0') return this.$message.error('错误：' + result_.RetMsg)

      // this.setDataArr(item)
      this.info.Details = this.info.Details.concat(result_.Message)
      this.visible = false
    },
    // 获取明细
    async GetStockSellByStockInv(MaterialRefIDXS) {
      // if (!this.info.StockCode) return this.$message.info('请选择仓库')
      //, stockCode: this.info.StockCode
      return await this.$api.GetStockSellByStockInv({ MaterialRefIDXS })
    },
    // 添加一个
    async  addGoodOne(row) {
      console.log(row)
      if (row.StockNum <= 0) return this.$message.info('可用数量不能小于1')
      const result = await this.GetStockSellByStockInv(row.IDX)
      if (result.RetCode !== '0') return this.$message.error('错误：' + result.RetMsg)
      console.log(result)
      result.Message.map(item => {
        this.setDataArr(item)
        // this.info.Details.push(item)
      })
      this.$refs.tableDataProduct.toggleRowSelection(row, true)
      this.$set(row, 'disabled', true)
    },
    // 移除物料
    delProduct(index, row) {
      this.info.Details.splice(index, 1)
    },
    // 检测物料是否已被选择
    checkProduceIs() {
      this.info.Details.map(item => {
        this.tableDataProduct.map(item1 => {
          this.$set(item1, 'disabled', false)
          if (item.materialRefCompanyID === item1.IDX) {
            this.$nextTick(() => {
              this.$refs.tableDataProduct.toggleRowSelection(item1, true)
              this.$set(item1, 'disabled', true)
            })
          }
        })
      })
    },
    showAddProduct() {
      // if (!this.info.StockCode) return this.$message.info('请选择仓库')
      this.handleCurrentChange1()
      this.visible = true
    },
    // 入库仓库 QueryInStockList
    QueryInStockList() {
      this.$api.QueryInStockList().then(res => {
        this.BuyersTerminal = res.Message
        console.log(res.Message)
      })
    },
    async getTableDataAsync(page, pageSize) {
      // api/QueryStockMaterialList
      // this.formSearch.PageIndex = page || 1
      // this.formSearch.PageSize = this.tablePageSize
      // console.log(' this.formSearch', this.formSearch)
      // const response = await this.$api.QueryStockMaterialList(this.formSearch)
      // console.log('response', response)
      // if (response.RetCode !== '0') return this.$message.error('错误：' + response.Message)
      // this.tableData = response.Message
      // this.tableTotalSize = response.Total
      return { Message: [], Total: 0 }
    }
  }
}
export default Index
